export default {
    nav: {
        main: {
            assets: 'Stamgegevens',
            supplyChain: 'Supply Chain',
            janKrediet: 'Jan Krediet',
            supplier: 'Leverancier',
        },
        assets: {
            suppliers: 'Leverancies',
            articles: 'Artikelen',
            customers: 'Klanten',
            transportCompanies: 'Transportbedrijven',
            users: 'Gebruikers',
            integrations: 'Integraties',
            exactErrors: 'Exact Foutmeldingen',
            globalValues: 'Instellingen',
            globalFiles: 'Bijzondere bestanden',
            emailTemplates: 'Email sjablonen',
        },
        audit: {
            accessLog: 'Toegangslog',
        },
        account: {
            account: 'Account',
            changelog: 'Changelog'
        },
        supplyChain: {
            distributionManager: 'Distributie',
            salesOrders: 'Verkooporders',
            distributionOrders: 'Distributieorders',
            purchaseOrders: 'Inkooporders',
            transportOrders: 'Transportorders',
            articles: 'Artikelen',
            defects: 'Defects',
        },
        janKrediet: {
            orders: 'Orders',
            inboundOrders: 'Inboundorders',
            outboundOrders: 'Outboundorders',
            cancelled: 'Geannuleerde Regels',
        },
        supplier: {
            dashboard: 'Dashboard',
            articles: 'Artikelen',
        },
        prompt: {
            leave: 'U heeft nog wijzigingen die niet zijn opgeslagen, als u verder gaat gaan deze verloren.',
             keepEditing: 'Blijf',
            continue: 'Verder gaan'
        },
    },
    supplierDashboard: {
        column: {
            newItems: { label: 'Nieuw' },
            confirmedItems: { label: 'Bevestigd' },
            inProduction: { label: 'In productie' },
            readyForTransport: { label: 'klaar voor transport' },
            confirmTransport: { label: 'Transport orders nog niet bevestigd' },
            loadingExpected: { label: 'Inkomende Transport orders' },
            done: { label: 'Verscheept' },
            dropshipPlanned: { label: 'Transport gepland' },
            dropshipDelivered: { label: 'Bezorgd bij klant' },
        },
        statusLabel: {
            toConfirm: 'Te Bevestigen',
            open: 'In Productie',
            inProduction: 'Gedeeltelijk',
            complete: 'Compleet',
        },
        expected: 'Verwacht',
        actionModal: {
            header: {
                confirmedItems: 'Bevestig het terugzetten van artikelen klaar voor transport',
                readyForTransport: 'Bevestig dat artikelen klaar zijn voor transport',
                done: 'Bevestig dat artikelen zijn geladen en verscheept door transport bedrijf',
                dropshipPlanned: 'Bevestig artikelen voor transport',
                dropshipDelivered: 'Bevestig levering van artikelen',
            }
        }
    },
    user: {
        overview: {
            title: 'Gebruikers',
            addButton: 'Nieuwe Gebruiker',
        },
        create: {
            title: 'Gebruiker Aanmaken',
        },
        edit: {
            title: 'Gebruiker Aanpassen',
        },
        field: {
            fullName: { label: 'Naam' },
            firstName: { label: 'Voornaam' },
            lastName: { label: 'Achternaam' },
            email: { label: 'E-mail' },
            language: { label: 'Taal' },
            password: { label: 'Wachtwoord' },
            dateJoined: { label: 'Toegevoegd Op' },
            groups: {
                label: 'Groepen',
                value: {
                    superuser: 'Supergebruiker',
                    admin: 'Administrator',
                    manager: 'Manager',
                    purchaser: 'Inkoper',
                    planner: 'Planner',
                    supplier: 'Leverancier',
                    distributor: 'Distributeur',
                    transport_partner: 'Transportpartner',
                },
                empty: 'Geen groepen toegekend',
            },
            supplier: {
                label: 'Leverancier',
                none: 'Geen leverancier toegekend',
            },
            transportCompany: {
                label: 'Transportbedrijf',
                none: 'Geen transportbedrijf toegekend',
            },
            isActive: {
                label: 'Actief',
            },
        },
        login: {
            title: 'Inloggen',
            forgotPasswordLink: 'Wachtwoord vergeten?',
            errors: {
                invalidCredentials: 'E-mail of wachtwoord is niet correct',
                unknown: 'Onbekende fout, status code: {{status}}', },
            loginButton: 'Inloggen',
        },
        passwordForgot: {
            title: 'Wachtwoord Vergeten',
            requestButton: 'Verstuur Email',
            requestedEmailText:
                'Er is een email met een wachtwoord reset code verzonden naar uw email adres.',
            loggedInError: 'Je bent ingelogd. Je kunt alleen wachtwoord vergeten aanvragen als je uitgelogd bent.',
        },
        resetPassword: {
            title: 'Wachtwoord Reset',
            resetButton: 'Reset',
            saveSuccess: 'Wachtwoord is succesvol gereset',
            loggedInError: 'Je bent ingelogd. Je kunt alleen wachtwoord resetten als je uitgelogd bent.',
        },
        changePassword: {
            title: 'Wachtwoord Aanpassen',
            field: {
                passwordOld: {
                    label: 'Huidig wachtwoord',
                },
                passwordNew: {
                    label: 'Nieuw wachtwoord',
                },
            },
            saveButton: 'Opslaan',
        },
        account: {
            title: 'Profiel',
            logoutButton: 'Uitloggen',
            info: {
                title: 'Gegevens',
            },
            password: {
                title: 'Wachtwoord Veranderen',
            },
            logout: {
                title: 'Uitloggen',
                confirm: 'Weet u zeker dat u wilt uitloggen?'
            },
        },
    },
    globalValue: {
        edit: {
            title: 'Waarde wijzigen van algemene instelling',
        },
        overview: {
            title: 'Algemene instellingen',
        },
        field: {
            key: {
                label: 'Naam instelling',
            },
            value: {
                label: 'Waarde',
            },
            writable: {
                label: 'Schrijfbaar?',
            },
            readable: {
                label: 'Leesbaar?',
            },
        },
    },
    globalFile: {
        edit: {
            title: 'Bijzonder bestand bewerken',
        },
        overview: {
            title: 'Bijzondere bestanden',
        },
        field: {
            key: {
                label: 'Naam voor bestand',
            },
            value: {
                label: 'Bestand',
            },
            writable: {
                label: 'Schrijfbaar?',
            },
            readable: {
                label: 'Leesbaar?',
            },
        },
    },
    accessLogEntry: {
        overview: {
            title: 'Auditlog',
        },
        field: {
            originalUser: {
                label: 'Oorspronkelijke gebruiker',
            },
            masqueradingAsUser: {
                label: 'Gemaskeerd als gebruiker',
            },
            ipAddress: {
                label: 'IP-adres',
            },
            method: {
                label: 'HTTP-methode',
            },
            endpoint: {
                label: 'Endpoint',
            },
            statusCode: {
                label: 'Statuscode',
            },
            additionalInfo: {
                label: 'Extra informatie',
            },
            requestTime: {
                label: 'Tijdstip van toegang',
            },
        },
    },
    myFilter: {
        custom: 'Nieuw',
        action: {
            unsetDefault: 'Gebruik niet als standaard',
            setDefault: 'Gebruik als standaard',
            setCurrent: 'Sla huidige filters op',
            delete: 'Verwijderen',
        },
    },
    tooltips: {
        masquerade: 'Voordoen als',
        edit: 'Aanpassen',
        delete: 'Verwijderen',
        view: 'Bekijk',
        restore:'Herstel',
        send: 'Versturen',
    },
    form: {
        repeat: 'Herhaal',
        fileType: {
            pdf: 'PDF',
            image: 'IMG',
            xls: 'XLS',
            any: 'Anders',
            none: 'Nog geen bestand geüpload',
        },
        saveButton: 'Opslaan',
        sendButton: 'Versturen',
        sortButton: 'Sorteren',
        deleteButton: 'Verwijderen',
        multiPick: {
            searchPlaceholder: 'Selecteer…',
            selectedText: '$1 uit $2 geselecteerd',
            noneSelectedText: 'Geen geselecteerd',
            selectAllButton: 'Alle',
            selectNoneButton: 'Geen',
        },
        notifications: {
            saveSuccess: 'Succesvol opgeslagen',
            deleteSuccess: 'Succesvol verwijderd',
            saveError: 'Fout met opslaan ({{status}})',
            saveValError: 'Niet alle informatie is correct ingevuld',
            saveAuthError: 'Niet geauthorizeerd',
            newAppVersion:
                'Nieuwe versie beschikbaar, klik om de pagina te verversen',
        },
        submitButton: 'Verstuur',
        cancelButton: 'Annuleren',
        addButton: 'Toevoegen',
        deleteConfirmation: 'Weet u zeker dat u dit wilt verwijderen?',
        restoreConfirmation: 'Weet u zeker dat u dit wilt herstellen?',
        startDate: 'Start Datum',
        endDate: 'Eind Datum',
        yes: 'Ja',
        no: 'Nee',
        either: 'Beide',
        search: 'Zoeken',
    },
    crash: {
        heading: 'Applicatie gecrasht',
        subHeading1: 'Onze excuses, maar de applicatie is gecrasht.',
        subHeading2: 'Ons team is op de hoogte gesteld.',
        reload: 'Herlaad pagina'
    },
    emailTemplate: {
        overview: {
            title: 'Email sjablonen' ,
            addButton: 'Nieuw sjabloon',
        },
        create: { title: 'Mail sjabloon aanmaken' },
        edit: { title: 'Mail sjabloon aanpassen' },
        add: { title: 'Mail sjabloon toevoegen' },
        field: {
            id: { label: 'ID' },
            isDefault: { label: 'Is standaard' },
            name: { label: 'Naam' },
            emailType: { label: 'Email type' },
            subject: { label: 'Onderwerp' },
            textContent: { label: 'Text variant van email', placeholder: 'Sommige mail clients supporten alleen tekst' },
            senderEmail: { label: 'Verzender' },
        }
    },
    emailTypes: {
        password_reset: 'Wachtwoord reset',
        user_activation: 'Account activatie',
        transport_order_request: 'Transport order request',
    },
    daycy: {
        week: {
            label: 'Wk',
            number: '{{weekNumber}}',
        },
        weekDay: {
            monday: 'Ma',
            tuesday: 'Di',
            wednesday: 'Wo',
            thursday: 'Do',
            friday: 'Vr',
            saturday: 'Za',
            sunday: 'Zo',
        },
        month: {
            january: 'Januari',
            february: 'Februari',
            march: 'Maart',
            april: 'April',
            may: 'Mei',
            june: 'Juni',
            july: 'Juli',
            august: 'Augustus',
            september: 'September',
            october: 'Oktober',
            november: 'November',
            december: 'December',
        },
        period: {
            am: 'AM',
            pm: 'PM',
        },
    },
    pageNotFoundMessage: 'Pagina niet gevonden',
    brandingMessage: 'Powered by Code Yellow',
    purchaseOrder: {
        overview: {
            title: 'Inkooporders',
            acceptButton: 'Accepteren',
            rejectButton: 'Afwijzen',
            addLine: 'Regel Toevoegen',
        },
        field: {
            supplier: { label: 'Leverancier' },
            orderDate: { label: 'Order' },
            productionDate: { label: 'Productie' },
            loadDate: { label: 'Laden' },
            unloadDate: { label: 'Lossen' },
            readyStatus: {
                label: 'Gereedstatus',
                value: {
                    '0_pending': 'In Afwachting',
                    '1_open': 'Open',
                    '2_partial': 'Gedeeltelijk',
                    '3_complete': 'Volledig',
                },
            },
            notes: { label: 'Notities' },
            threads: { label: 'Notitie bevat' },
            unresolvedThreads: { label: 'Heeft' },
        },
    },
    purchaseOrderLine: {
        field: {
            article: { label: 'Artikel' },
            quantity: { label: 'Aantal' },
            ready: { label: 'Gereed' },
        },
    },
    salesOrder: {
        overview: {
            title: 'Verkooporders',
            bulkCreatePurchaseOrders: 'Inkooporders Aanmaken',
            short: '{{count}} tekort',
            delayIssuePartially: 'Vertraag Gedeeltelijk',
            delayIssueFully: 'Vertraag Volledig',
            noIssues: 'Er zijn momenteel geen leveringsproblemen.',
            addresses: {
                order: 'Orderadres',
                delivery: 'Leveradres',
                invoice: 'Factuuradres',
            },
        },
        field: {
            customer: { label: 'Klant' },
            orderDate: { label: 'Order' },
            deliveryDate: { label: 'Levering' },
            notes: { label: 'Notities' },
            deliveryIssue: { label: 'Leveringsprobleem' },
            number: { label: 'SO Nummer' },

            deliveryName: { label: 'Naam' },
            deliveryPhoneNumber: { label: 'Telefoonnummer' },
            deliveryAddress: { label: 'Adres' },
            deliveryStreet: { label: 'Straat' },
            deliveryNumber: { label: 'Nummer' },
            deliveryNumberSuffix: { label: 'Toevoeging' },
            deliveryZipCode: { label: 'Postcode' },
            deliveryCity: { label: 'Plaats' },
            deliveryCountry: { label: 'Land' },

            invoiceName: { label: 'Bedrijf' },
            invoiceEmailAddress: { label: 'Emailadres' },
            invoicePhoneNumber: { label: 'Telefoonnummer' },
            invoiceAddress: { label: 'Adres' },
            invoiceStreet: { label: 'Straat' },
            invoiceNumber: { label: 'Nummer' },
            invoiceNumberSuffix: { label: 'Toevoeging' },
            invoiceZipCode: { label: 'Postcode' },
            invoiceCity: { label: 'Plaats' },
            invoiceCountry: { label: 'Land' },

            deliveryStatus: {
                label: 'Leverstatus',
                value: {
                    open: 'Open',
                    partial: 'Gedeeltelijk',
                    complete: 'Compleet',
                    cancelled: 'Geannuleerd',
                },
            },
            invoiceStatus: {
                label: 'Factuurstatus',
                value: {
                    open: 'Open',
                    partial: 'Gedeeltelijk',
                    complete: 'Compleet',
                    cancelled: 'Geannuleerd',
                },
            },

            description: { label: 'Beschrijving' },
            salesPerson: { label: 'Verkoper' },
            customerReference: { label: 'Klantreferentie' },
            isBol: { label: 'Is Bol.com' },
        },
        filter: {
            late: {
                label: 'Laat',
                info: 'Als artikelen nog van de leverancier moeten komen had het transport al gepland moeten zijn.',
            },
            upcoming: {
                label: 'Urgent',
                info: 'Als artikelen nog van de leverancier moeten komen moet het transport vandaag gepland worden.',
            },
            plannable: {
                label: 'Planbaar',
                info: 'Er zijn artikelen die gepland kunnen worden.',
            },
        },
    },
    salesOrderLine: {
        field: {
            article: { label: 'Artikel' },
            quantity: { label: 'Aantal' },
            distributed: { label: 'Gepland' },
            transported: { label: 'Getransporteerd' },
            delivered: { label: 'Geleverd' },
        },
    },
    article: {
        overview: {
            title: 'Artikelen',
            orderModal: {
                title: 'Order naar Voorraad',
            },
            addToBasket: 'Voeg toe aan Winkelmand',
            addDemandToBasket: 'Voeg Vraag toe aan Winkelmand',
            bulkAddToBasket: 'Voeg toe aan Winkelmand ({{count}})',
            bulkAddDemandToBasket: 'Voeg Vraag toe aan Winkelmand ({{count}})',
            shoppingBasket: {
                empty: 'Er zit nog niks in je winkelmand.',
                purchaseButton: 'Inkopen',
                discard_one: 'Als je deze pagina verlaat verlies je je winkelmand met {{count}} artikel, weet je zeker dat je de pagina wilt verlaten?',
                discard_other: 'Als je deze pagina verlaat verlies je je winkelmand met {{count}} artikelen, weet je zeker dat je de pagina wilt verlaten?',
            },
        },
        create: { title: 'Artikel Aanmaken' },
        edit: { title: 'Artikel Aanpassen' },
        field: {
            name: { label: 'Naam' },
            supplier: { label: 'Leverancier' },
            suppliers: { label: 'Leveranciers' },
            productionTime: { label: 'Productietijd (d)' },
            transportTime: { label: 'Transporttijd (d)' },
            fromStock: { label: 'Uit DC Voorraad' },
            minStockLevel: { label: 'Min Voorraad' },
            maxStockLevel: { label: 'Max Voorraad' },
            currentStock: { label: 'Huidige Voorraad' },
            projectedStock: { label: 'Verwachte Voorraad' },
            demand: { label: 'Vraag' },
            cbm: { label: 'CBM' },
            kg: { label: 'kg' },
        },
        button: {
            filter: {
                activate: 'Filter SOs op artikel',
                deactivate: 'Filter op SOs uitzetten',
            }
        },
    },
    supplier: {
        overview: { title: 'Leveranciers' },
        create: { title: 'Leverancier Aanmaken' },
        edit: { title: 'Leverancier Aanpassen' },
        field: {
            name: { label: 'Naam' },
            emailAddress: { label: 'Emailadres' },
            phoneNumber: { label: 'Telefoonnummer' },
            address: { label: 'Adres' },
            street: { label: 'Straat' },
            number: { label: 'Nummer' },
            numberSuffix: { label: 'Toevoeging' },
            zipCode: { label: 'Postcode' },
            city: { label: 'Plaats' },
            country: { label: 'Land' },
            dropshipment: { label: 'Dropshipment' },
        },
    },
    customer: {
        overview: { title: 'Klanten' },
        create: { title: 'Klant Aanmaken' },
        edit: { title: 'Klant Aanpassen' },
        field: {
            name: { label: 'Naam' },
            emailAddress: { label: 'Emailadres' },
            phoneNumber: { label: 'Telefoonnummer' },
            address: { label: 'Adres' },
            street: { label: 'Straat' },
            number: { label: 'Nummer' },
            numberSuffix: { label: 'Toevoeging' },
            zipCode: { label: 'Postcode' },
            city: { label: 'Plaats' },
            country: { label: 'Land' },
        },
    },
    integration: {
        overview: { title: 'Integraties' },
        create: { title: 'Integratie Aanmaken' },
        edit: { title: 'Integratie Aanpassen' },
        field: {
            id: { label: 'ID' },
            type: {
                label: 'Type',
                value: {
                    exact: 'Exact',
                    jan_krediet: 'Jan Krediet',
                },
            },
            description: { label: 'Beschrijving' },
            errorCount: { label: '# Foutmeldingen' },
        },
    },
    exactIntegration: {
        overview: {
            activateButton: 'Activeren',
            activateResult: {
                ok: 'Exact is succesvol geactiveerd',
                error: 'Exact activatiefoutmelding: {{error}}',
            },
            infoModal: {
                title: 'Exact Sync Info',
                readHeader: 'Leesonderwerpen',
                writeHeader: 'Schrijfonderwerpen',
                topic: {
                    account: 'Relaties',
                    address: 'Adressen',
                    supplier_item: 'Leverancierartikelen',
                    item_extra_field: 'Artikel Extra Velden',
                    item_warehouse: 'Artikelmagazijnen',
                    item: 'Artikelen',
                    sales_order_line: 'Verkooporderregels',
                    sales_order: 'Verkooporders',
                    purchase_order_line: 'Bestellingregels',
                    purchase_order: 'Bestellingen',
                    goods_receipt: 'Goederenontvangsten',
                    goods_delivery: 'Goederenleveringen',
                },
            },
        },
        field: {
            country: {
                label: 'Land',
                value: {
                    nl: 'NL',
                    be: 'BE',
                    de: 'DE',
                },
            },
            division: { label: 'Divisie' },
        },
    },
    janKredietIntegration: {
        field: {
            apiKey: { label: 'API Key' },
            isTest: { label: 'Is Test' },
        },
    },
    exactError: {
        overview: {
            title: 'Exact Foutmeldingen',
            topicHeader: {
                account_one: 'Relatie ({{count}})',
                account_other: 'Relaties ({{count}})',
                item_one: 'Artikel ({{count}})',
                item_other: 'Artikel ({{count}})',
                supplier_item_one: 'Artikelleverancier ({{count}})',
                supplier_item_other: 'Artikelleveranciers ({{count}})',
                sales_order_one: 'Verkooporder ({{count}})',
                sales_order_other: 'Verkooporders ({{count}})',
                sales_order_line_one: 'Verkooporderregel ({{count}})',
                sales_order_line_other: 'Verkooporderregels ({{count}})',
                purchase_order_one: 'Inkooporder ({{count}})',
                purchase_order_other: 'Inkooporders ({{count}})',
                purchase_order_line_one: 'Inkooporderregel ({{count}})',
                purchase_order_line_other: 'Inkooporderregels ({{count}})',
                goods_receipt_one: 'Goederenontvangst ({{count}})',
                goods_receipt_other: 'Goederenontvangsten ({{count}})',
                goods_delivery_one: 'Goederenlevering ({{count}})',
                goods_delivery_other: 'Goederenleveringen ({{count}})',
            },
        },
        field: {
            integration: { label: 'Integratie' },
            topic: {
                label: 'Onderwerp',
                value: {
                    account: 'Relatie',
                    item: 'Artikel',
                    supplier_item: 'Artikelleverancier',
                    sales_order: 'Verkooporder',
                    sales_order_line: 'Verkooporderregel',
                    purchase_order: 'Inkooporder',
                    purchase_order_line: 'Inkooporderregel',
                    goods_receipt: 'Goederenontvangst',
                    goods_delivery: 'Goederenlevering',
                },
            },
            status: {
                label: 'Status',
                value: {
                    to_create: 'Aan te maken',
                    create_error: 'Aanmaakfoutmelding',
                    to_update: 'Aan te passen',
                    update_error: 'Aanpasfoutmelding',
                    to_delete: 'Te verwijderen',
                    delete_error: 'Verwijderfoutmelding',
                    up_to_date: 'Up to Date',
                    placeholder: 'Placeholder',
                },
            },
            error: { label: 'Foutmelding' },
            payloadCount: { label: '# Gebeurtenissen' },
            blockingCount: { label: '# Geblokkeerd' },
        },
    },
    exactPayload: {
        field: {
            exactId: { label: 'Exact ID' },
        },
    },
    json: {
        entries_one: '{{count}} regel',
        entries_other: '{{count}} regels',
    },
    transportCompany: {
        overview: { title: 'Transportbedrijven' },
        create: { title: 'Transportbedrijf Aanpassen' },
        edit: { title: 'Transportbedrijf Aanmaken' },
        field: {
            name: { label: 'Naam' },
            email: { label: 'Email' },
            noApprovalRequired: { label: 'No approval required' },
        },
    },
    stock: {
        overview: {
            title: 'Voorraad',
            date: 'Datum',
        },
        field: {
            article: { label: 'Artikel' },
            startDate: { label: 'Startdatum' },
            endDate: { label: 'Einddatum' },
            inProduction: { label: 'In Productie' },
            toTransport: { label: 'Te Transporteren' },
            inTransport: { label: 'In Transport' },
            toDistribute: { label: 'Te Distribueren' },
        },
    },
    distributionOrder: {
        overview: { title: 'Distributieorders' },
        create: { title: 'Distributieorder Aanmaken' },
        edit: {
            title: 'Distributieorder Aanpassen',
            allocateInTransport: 'Alloceer van Transport',
            allocateToTransport: 'Alloceer van Leverancier',
            allocateInStock: 'Alloceer van Voorraad',
            chooseDistributionOrTransport: {
                header: 'Distributie of Transport',
                content: 'Je hebt een artikel geselecteerd dat zowel aan de distributieorder als de transportorder toegevoegd kan worden. Kies aan welke je het toe wil voegen.',
                addToDistribution: 'Distributie',
                addToTransport: 'Transport',
                addToBoth: 'Beide',
            },
            confirmPartial: {
                header: 'Gedeeltelijke Distributieorder',
                content: 'Sommige regels in de distributieorder zijn nog niet gealloceerd en kunnen dus niet opgeslagen worden. Wil je de distributieorder opslaan als een gedeeltelijke distributieorder zonder deze regels?',
                yesAndBlock: 'Ja & Blokkeer',
            },
            checkUnblock: {
                header: 'Deblokkeer Distributieorder',
                content: 'Alle regels in de distributieorder zijn gealloceerd maar de order is nog gemarkeerd als geblokkeerd. Wil je de distributieorder deblokkeren?',
            },
            confirmDelete: {
                header: 'Distributieorder Verwijderen',
                content: 'Geen van de regels in de distributieorder is gealloceerd en kunnen dus niet opgeslagen worden. Sinds een distributieorder regels moet hebben kan deze ook niet opgeslagen worden. Wil je in plaats daarvan de distributieorder verwijderen?',
            },
            confirmUnallocate: {
                header: 'Distributieorder Dealloceren',
                content: 'Je probeert het leveringstype aan te passen van een distributieorder met allocaties. Dit zal de huidige allocaties ongeldig maken en daarom zullen ze moeten worden verwijderd. Weet je zeker dat je dit wil doen?',
            },
            saveAndSend: 'Opslaan & Versturen',
        },
        field: {
            deliveryDate: { label: 'Gecommuniceerde Levering' },
            expectedDeliveryDate: { label: 'Verwachte Levering' },
            expectedDeliveryDifference: {
                value: {
                    early: 'Vroeg',
                    on_time: 'Op Tijd',
                    late: 'Laat',
                },
            },
            deliveryStatus: {
                label: 'Leveringsstatus',
                value: {
                    to_transport: 'Te Transporteren',
                    in_transport: 'In Transport',
                    blocked: 'Geblokkeerd',
                    to_plan: 'Te Plannen',
                    planned: 'Gepland',
                    delivered: 'Geleverd',
                },
            },
            transportOrders: { draft: 'Concept TOs' },
            directToCustomer: {
                label: 'Leveringstype',
                value: {
                    true: 'Direct',
                    false: 'Gedistribueerd',
                },
            },
            hasShortage: { info: 'Er zijn niet genoeg artikelen geleverd om de order uit te voeren, verifiëer de artikelen en update de distributieorder' },
            customerType: {
                label: 'Klanttype',
                value: {
                    business: 'B2B',
                    customer: 'B2C',
                    pick_up: 'Ophalen',
                    parcel: 'Pakket',
                },
            },
            blocked: { label: 'Geblokkeerd' },
            do: { label: 'DO nummer' },
        },
    },
    distributionOrderLine: {
        field: {
            article: { label: 'Artikel' },
            quantity: { label: 'Aantal' },
            deliveryIssue: {
                label: 'Leveringsprobleem',
                value: '{{count}} tot {{date}}',
            },
            transportBatch: { label: 'Bron' },
        },
    },
    transportOrder: {
        create: { title: 'Transportorder Aanmaken' },
        edit: {
            title: 'Transportorder Aanpassen',
            leftoverLoad: 'Overgebleven Lading',
            saveAndSend: 'Opslaan & Versturen',
        },
        overview: {
            title: 'Transportorders',
            addTransportOrderButton: 'Transportorder Toevoegen',
            addTransportOrderModal: { title: 'Transportorder Toevoegen' },
            date: 'Datum',
            open: {
                totalCbm: '{{value}} Totaal',
                remainingCbm: '{{value}} Beschikbaar',
                transportButton: 'Transport',
                empty: 'Er is niks om te transporteren.',
            },
            markDone: 'Markeer als Klaar',
        },
        field: {
            transportCompany: { label: 'Transportbedrijf' },
            startDate: { label: 'Start' },
            loadDate: { label: 'Laden' },
            unloadDate: { label: 'Lossen' },
            totalCbm: { label: 'Totale CBM' },
            totalKg: { label: 'Totale kg' },
            notes: { label: 'Notities' },
            activities: { label: 'Activiteiten' },
            isDone: { label: 'Is Klaar' },
            _emailTemplate: { label: 'Email Sjabloon' },
            rejectReason: { label: 'Reden van afwijzing' },
            licensePlate: { label: 'Nummerbord' },
            loadPlannedAt: { label: 'Laden gepland op' },
            unloadPlannedAt: { label: 'Lossen gepland op' },
            loadDoneAt: { label: 'Laden gedaan op' },
            unloadDoneAt: { label: 'Lossen gedaan op' },
        },
    },
    productionBatch: {
        field: {
            quantity: { label: 'Aantal' },
            readyDate: { label: 'Gereed' },
        },
    },
    thread: {
        actions: {
            reply: 'Reageren',
            resolve: 'Afvinken',
            resolveAndReply: 'Reageren en afvinken ongedaan maken',
            hideComments: 'Reacties verbergen',
            showComments: 'Toon {{count}} reacties',
        },
        field: {
            reply: { label: 'Reageer...' },
            message: { label: 'Nieuwe notitie...' },
            notes: {
                more: '{{count}} meer',
                none: 'Notitie toevoegen...',
            }
        },
        button: {
            showResolved: 'Afgevinkte notities tonen...',
            hideResolved: 'Afgevinkte notities verbergen...',
            hasUnresolved: 'Afgevinkte notities',
            hasResolved: 'Niet afgevinkte notities',
        }
    },
    transportOrderLine: {
        field: {
            quantity: { label: 'Aantal' },
            productionBatch: { label: 'Gereed' },
        },
    },
    activity: {
        field: {
            type: {
                label: 'Type',
                value: {
                    load: 'Laden',
                    unload: 'Lossen',
                },
            },
            account: { label: 'Relatie' },
            plannedAt: { label: 'Gepland Op' },
            doneAt: { label: 'Gedaan Op' },
        },
    },
    activityLine: {
        field: {
            quantity: { label: 'Aantal' },
        },
    },
    distributionManager: {
        stock: 'Voorraad',
        articles: 'Artikelen',
        notAllocated: 'Niet Gealloceerd',
        setFilter: {
            article: 'Filter op artikel {{code}} {{name}}',
            transport: 'Filter op artikelen die klaar zijn voor transport',
            distributionOrder: 'Filter op artikelen die gealloceerd kunnen worden voor distributie',
        },
        clearFilter: {
            article: 'Verwijder filter op artikel {{code}} {{name}}',
            transport: 'Verwijder filter op artikelen die klaar zijn voor transport',
            distributionOrder: 'Verwijder filter op artikelen die gealloceerd kunnen worden voor distributie',
        },
        pendingUnload: {
            label: 'In Afwachting van Transport',
            info: 'Je hebt artikelen van de leverancier gealloceerd waarvoor nog geen transport gepland is. Je moet eerst dit transport plannen voordat je deze distributieorder op kunt slaan.',
        },
        sendModal: {
            title: 'Transportorder Versturen',
        },
        newTransportOrder: 'Nieuwe Transportorder',
        transportedQuantityBelowDistributed: 'Aantal mag niet lager zijn dan gedistribueerd',
        hasUndistributedToCustomer: 'Artikelen gaan direct naar de klant maar is niet volledig gedistribueerd, dit is niet toegestaan.',
        canAllocateStatus: {
            fully: 'Kan volledig gealloceerd worden',
            partially: 'Kan gedeeltelijk gealloceerd worden',
            not: 'Kan niet gealloceerd worden',
            blocked: 'Geblokkeerd',
            wait: 'Leverancier heeft artikelen nog niet gemaakt',
        },
        linkedTo: {
            pre: 'Gelinkt aan ',
            post: '',
            unlink: {
                title: 'Link Verwijderen',
                content: 'Weet je zeker dat je de link tussen deze orderregels wilt verwijderen?',
            },
        },
    },
    transportBatch: {
        field: {
            distributed: { label: 'Gedistribueerd' },
            quantity: { label: 'Aantal' },
            load: { label: 'Laden' },
            unload: { label: 'Lossen' },
        },
        destroyModal: {
            header: 'Markeer als defect',
        },
    },
    defect: {
        field: {
            origin: { label: 'Oorsprong',
                supplier: 'Leverancier',
                transport: 'Transport',
                distributor: 'Distributie',
                unknown: 'Onbekend',
            },
            missing: { label: 'Onvindbaar' },
            id: { label: 'ID' },
            quantity: { label: 'Hoeveelheid' },
            description: { label: 'Beschrijving' },
            proof: { label: 'Foto\'s' },
        },
        button: {
            toStock: { label: 'Terugzetten naar voorraad' },
        },
        overview: {
            title: 'Defecten en missende artikelen',
            addButton: 'Defect of missend artikel toevoegen',
        },
        edit: { title: 'Defect of missend artikel aanpassen' },
        create: { title: 'Defect of missend artikel toevoegen' },
    },
    image: {
        button: { add: { text: 'Nog een foto toevoegen' } },
    },
    janKredietOrder: {
        overview: { title: 'Orders' },
        field: {
            status: {
                label: 'Status',
                value: {
                    to_sync: 'Te Synchroniseren',
                    synced: 'Gesynchroniseerd',
                    sync_error: 'Synchronisatiefout',
                    to_release: 'Vrij te Geven',
                    released: 'Vrijgegeven',
                    release_error: 'Vrijgeeffout',
                    done: 'Klaar',
                },
            },
            error: { label: 'Foutmelding' },
            lines: {
                label: 'Regels',
                value_one: '{{count}} regel',
                value_other: '{{count}} regels',
            },
            transportDate: { label: 'Transport' },
            distributionDate: { label: 'Distributie' },
            reference: { label: 'JK Referentie' },
            janKredietId: {
                label: 'JK ID',
                isOutbound: 'Er is nog geen JK Transport Order ID beschikbaar, daarom tonen we in plaats daarvan het JK Uitslag ID.',
                isOnHold: 'De JK Transport Order staat nog op on hold.',
                hasStockProblem: 'JK rapporteert dat er niet genoeg voorraad is om de uitslagorder te vervullen.',
            },
        },
    },
    janKredietOrderLine: {
        field: {
            quantity: { label: 'Aantal' },
        },
    },
    janKredietInboundOrder: {
        overview: { title: 'Inboundorders' },
        field: {
            status: {
                label: 'Status',
                value: {
                    to_sync: 'Te Synchroniseren',
                    synced: 'Gesynchroniseerd',
                    sync_error: 'Synchronisatiefout',
                    done: 'Klaar',
                },
            },
            error: { label: 'Foutmelding' },
            lines: {
                label: 'Regels',
                value_one: '{{count}} regel',
                value_other: '{{count}} regels',
            },
            transportDate: { label: 'Transport' },
        },
    },
    janKredietInboundOrderLine: {
        field: {
            quantity: { label: 'Aantal' },
        },
    },
    janKredietOutboundOrder: {
        overview: { title: 'Outboundorders' },
        field: {
            status: {
                label: 'Status',
                value: {
                    to_sync: 'Te Synchroniseren',
                    synced: 'Gesynchroniseerd',
                    sync_error: 'Synchronisatiefout',
                    done: 'Klaar',
                },
            },
            error: { label: 'Foutmelding' },
            lines: {
                label: 'Regels',
                value_one: '{{count}} regel',
                value_other: '{{count}} regels',
            },
            distributionDate: { label: 'Distributie' },
        },
    },
    janKredietOutboundOrderLine: {
        field: {
            quantity: { label: 'Aantal' },
        },
    },
    load: {
        field: {
            plannedAt: { label: 'Gepland Op' },
            expectedAt: { label: 'Verwacht Op' },
            doneAt: { label: 'Klaar Op' },
            notes: { label: 'Notities' },
            packingList: { label: 'Packinglist' },
        },
    },
    unload: {
        field: {
            plannedAt: { label: 'Gepland Op' },
            expectedAt: { label: 'Verwacht Op' },
            doneAt: { label: 'Klaar Op' },
            notes: { label: 'Notities' },
            proofOfDelivery: { label: 'Bewijs van levering' },
        },
    },
     emailToken: {
        acceptPage: {
            invalid: { message: 'Het is niet meer mogelijk om op deze aanvraag te reageren. De aanvraag is verlopen of een reactie is al gegeven.' },
            accepted: { message: 'Bedankt voor het accepteren van onze aanvraag!' }
        },
        declinePage: {
            rejectButton: { label: 'Aanvraag afwijzen' },
            rejectReason: { message: 'Geef a.u.b. een reden voor uw afwijzing.' },
            invalid: { message: 'Het is niet meer mogelijk om op deze aanvraag te reageren. De aanvraag is verlopen of een reactie is al gegeven.' },
            accepted: { message: 'Uw reactie is verwerkt' }
        }
    },
    combinedJanKredietOrderLineJanKredietOutboundOrderLine: {
        overview: {
            title: 'Geannuleerde Regels',
            resolveButton: 'Geregeld met Jan Krediet',
        },
        field: {
            reference: { label: 'Referentie' },
        },
    },
};
