import { observable, runInAction } from 'mobx';
import { Model, Store, Casts } from 'store/Base';
import { JanKredietOrderLineStore } from './JanKredietOrderLine';
import { TransportOrder } from './TransportOrder';
import { DistributionOrder } from './DistributionOrder';
import { showSaveNotification } from 'helpers/notification';

export class JanKredietOrder extends Model {
    static backendResourceName = 'jan_krediet_order';
    static omitFields = ['reference', 'transportDate'];
    static STATUSES = ['to_sync', 'synced', 'sync_error', 'to_release', 'released', 'release_error', 'done'];

    @observable id = null;
    @observable status = 'to_sync';
    @observable error = null;
    @observable reference = null;
    @observable transportDate = null;
    @observable janKredietId = null;

    async resync() {
        await this.wrapPendingRequestCount(this.api.post(`${this.url}resync/`));
        runInAction(() => {
            showSaveNotification();
            this.status = 'to_sync';
            this.error = null;
        });
    }

    async rerelease() {
        await this.wrapPendingRequestCount(this.api.post(`${this.url}rerelease/`));
        runInAction(() => {
            showSaveNotification();
            this.status = 'to_release';
            this.error = null;
        });
    }

    relations() {
        return {
            transportOrder: TransportOrder,
            distributionOrder: DistributionOrder,
            lines: JanKredietOrderLineStore,
        };
    }

    casts() {
        return {
            transportDate: Casts.date,
        };
    }
}

export class JanKredietOrderStore extends Store {
    Model = JanKredietOrder;
    static backendResourceName = 'jan_krediet_order';
}
